import Vue from 'vue';
import VueCookies from 'vue-cookies';
import JsonCSV from 'vue-json-csv';
import VueEditor from 'vue2-editor';
import VueMoment from 'vue-moment';
import Datepicker from 'vuejs-datepicker';
import vueNumeralFilterInstaller from 'vue-numeral-filter';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

Vue.component('downloadCsv', JsonCSV);
Vue.component(Datepicker);

Vue.use(VueCookies);
Vue.use(VueEditor);
Vue.use(VueMoment);
Vue.use(vueNumeralFilterInstaller, { locale: 'en-gb' });

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
