<template>
  <div class="footer">
    <div class="grid-x grid-padding-x pad-bottom">
      <div class="cell small-12">
        <span class="footer-border"></span>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    authData() {
      return this.$store.getters.authData;
    },
    currentUserName() {
      return this.$store.getters.currentUserName;
    },
  },
};
</script>
