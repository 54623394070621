import Vue from 'vue';
import VueRouter from 'vue-router';
import Store from '@/store/index';
import Index from '@/views/Index.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
  },
  {
    path: '/log-out',
    name: 'Logout',
    component: () => import(/* webpackChunkName: "login" */ '../views/Logout.vue'),
  },
  {
    path: '/request-password',
    name: 'RequestPassword',
    component: () => import(/* webpackChunkName: "requestpassword" */ '../views/RequestPassword.vue'),
  },
  {
    path: '/reset-password/:hash/:id',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "resetpassword" */ '../views/ResetPassword.vue'),
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
    beforeEnter(to, from, next) {
      if (!Store.state.idToken) {
        Store.dispatch('checkStoredToken');
      }
      if (Store.state.idToken) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/add-file',
    name: 'AddFile',
    component: () => import(/* webpackChunkName: "addFile" */ '../views/AddFile.vue'),
    beforeEnter(to, from, next) {
      if (!Store.state.idToken) {
        Store.dispatch('checkStoredToken');
      }
      if (Store.state.idToken) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/add-user',
    name: 'AddUser',
    component: () => import(/* webpackChunkName: "addUser" */ '../views/AddUser.vue'),
    beforeEnter(to, from, next) {
      if (!Store.state.idToken) {
        Store.dispatch('checkStoredToken');
      }
      if (Store.state.idToken) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/edit-user/:id',
    name: 'EditUser',
    component: () => import(/* webpackChunkName: "editUser" */ '../views/EditUser.vue'),
    beforeEnter(to, from, next) {
      if (!Store.state.idToken) {
        Store.dispatch('checkStoredToken');
      }
      if (Store.state.idToken) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/view-open-interest/',
    name: 'ViewOpenInterests',
    component: () => import(/* webpackChunkName: "editUser" */ '../views/ViewOpenInterest.vue'),
    beforeEnter(to, from, next) {
      if (!Store.state.idToken) {
        Store.dispatch('checkStoredToken');
      }
      if (Store.state.idToken) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/view-legacy/',
    name: 'ViewLegacy',
    component: () => import(/* webpackChunkName: "viewLegacy" */ '../views/ViewLegacy.vue'),
    beforeEnter(to, from, next) {
      if (!Store.state.idToken) {
        Store.dispatch('checkStoredToken');
      }
      if (Store.state.idToken) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/view-tff/',
    name: 'ViewTFF',
    component: () => import(/* webpackChunkName: "viewTff" */ '../views/ViewTff.vue'),
    beforeEnter(to, from, next) {
      if (!Store.state.idToken) {
        Store.dispatch('checkStoredToken');
      }
      if (Store.state.idToken) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/view-legacy-futures/',
    name: 'ViewLegacyFutures',
    component: () => import(/* webpackChunkName: "editUser" */ '../views/ViewLegacyFutures.vue'),
    beforeEnter(to, from, next) {
      if (!Store.state.idToken) {
        Store.dispatch('checkStoredToken');
      }
      if (Store.state.idToken) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/view-legacy-options/',
    name: 'ViewLegacyOptions',
    component: () => import(/* webpackChunkName: "editUser" */ '../views/ViewLegacyOptions.vue'),
    beforeEnter(to, from, next) {
      if (!Store.state.idToken) {
        Store.dispatch('checkStoredToken');
      }
      if (Store.state.idToken) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/view-legacy-combined/',
    name: 'ViewLegacyCombined',
    component: () => import(/* webpackChunkName: "editUser" */ '../views/ViewLegacyCombined.vue'),
    beforeEnter(to, from, next) {
      if (!Store.state.idToken) {
        Store.dispatch('checkStoredToken');
      }
      if (Store.state.idToken) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/view-tff-futures/',
    name: 'ViewTffFutures',
    component: () => import(/* webpackChunkName: "tffFutures" */ '../views/ViewTffFutures.vue'),
    beforeEnter(to, from, next) {
      if (!Store.state.idToken) {
        Store.dispatch('checkStoredToken');
      }
      if (Store.state.idToken) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/view-tff-options/',
    name: 'ViewTffOptions',
    component: () => import(/* webpackChunkName: "tffOptions" */ '../views/ViewTffOptions.vue'),
    beforeEnter(to, from, next) {
      if (!Store.state.idToken) {
        Store.dispatch('checkStoredToken');
      }
      if (Store.state.idToken) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/view-tff-combined/',
    name: 'ViewTffCombined',
    component: () => import(/* webpackChunkName: "tffCombined" */ '../views/ViewTffCombined.vue'),
    beforeEnter(to, from, next) {
      if (!Store.state.idToken) {
        Store.dispatch('checkStoredToken');
      }
      if (Store.state.idToken) {
        next();
      } else {
        next('/');
      }
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // eslint-disable-next-line
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

export default router;
