<template>
  <div id="app">
    <app-header></app-header>
    <app-styles></app-styles>
    <div class="content">
      <router-view/>
    </div>
    <app-footer></app-footer>
    <app-cookie-bar
      v-if="cookiesAccepted === false"
      v-on:cookiesUpdated="cookiesAccepted = true"></app-cookie-bar>
  </div>
</template>

<script>
import Styles from '@/components/ztyles/Styles.vue';
import Header from '@/components/siteparts/Header.vue';
import Footer from '@/components/siteparts/Footer.vue';
import CookieBar from '@/components/siteparts/CookieBar.vue';

export default {
  name: 'App',
  components: {
    appCookieBar: CookieBar,
    appFooter: Footer,
    appHeader: Header,
    appStyles: Styles,
  },
  data() {
    return {
      cookiesAccepted: false,
    };
  },
  methods: {
    checkCookie() {
      const acceptedCookieTest = this.$cookies.get('acceptedCookies');
      if (acceptedCookieTest === 'true') {
        this.cookiesAccepted = true;
      }
    },
  },
  mounted() {
    this.checkCookie();
  },
};
</script>
