<template>
  <div class="cookie-bar">
    <div class="grid-container pad-top pad-bottom--large">
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 medium-7">
          <p>
            We use cookies on our website.
          </p>
        </div>
        <div class="cell small-12 medium-5">
          <div class="grid-x">
            <div class="cell small-12">
              <button
                v-on:click="acceptCookies"
                class="button expanded">Accept All Cookies</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CookieBar',
  methods: {
    acceptCookies() {
      this.$cookies.set('acceptedCookies', 'true', 60 * 60 * 24 * 30);
      this.$emit('cookiesUpdated');
    },
  },
};
</script>

<style lang="scss" scoped>
  .cookie-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #d7d8d9;

    p {
      color: #333;
    }

    .button {
      color: #333;
      background-color: transparent;
      text-transform: none;
      border: 1px solid #333;
      font-size: 14px;
      font-weight: normal;
    }
}
</style>
