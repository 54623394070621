<template>
  <div class="header">
    <div class="grid-x grid-padding-x pad-top">
      <div class="cell small-12 text-right">
        <ul v-if="isLoggedIn === true">
          <router-link class="button" tag="li" to="/dashboard">Dashboard</router-link>
          &nbsp;
          <router-link class="button" tag="li" to="/log-out">Log out</router-link>
        </ul>
        <ul v-else>
          <router-link class="button" tag="li" to="/">Log In</router-link>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    authData() {
      return this.$store.getters.authData;
    },
    currentUserName() {
      return this.$store.getters.currentUserName;
    },
    userLevel() {
      return this.$store.getters.userLevel;
    },
  },
};
</script>
