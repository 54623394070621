import axios from 'axios';

const instance = axios.create({
  headers: { Pragma: 'no-cache' },
  // Dev
  baseURL: 'https://nq-api.mmandb-dev.co.uk/',
  // Production
  // baseURL: 'https://nq-api.mmandb-dev.co.uk/',
});

export default instance;
