import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    idToken: null,
    userId: null,
    currentUserName: '',
    userEmail: '',
    currentLastLogIn: '',
    birdCage: null,
    loggedIn: false,
    userLevel: 0,
  },
  mutations: {
    authUser(state, userData) {
      state.idToken = userData.token;
      state.userId = userData.userId;
      state.currentUserName = userData.userName;
      state.currentLastLogIn = userData.lastLogIn;
      state.birdCage = userData.birdCage;
      state.loggedIn = true;
      state.userEmail = userData.email_address;
      state.userLevel = userData.userLevel;
    },
    clearAuthData(state) {
      state.idToken = null;
      state.userId = null;
      state.currentUserName = '';
      state.birdCage = null;
      state.loggedIn = false;
      state.userEmail = '';
      state.userLevel = 0;
    },
  },
  actions: {
    saveToken({ commit }, authData) {
      commit('authUser', {
        token: authData.token,
        userId: authData.user_id,
        birdCage: authData.cage,
        userName: authData.username,
        lastLogIn: authData.lastlogin,
        email_address: authData.email_address,
        userLevel: authData.userlevel,
      });
      const expirationDate = authData.expiresIn;
      localStorage.setItem('token', authData.token);
      localStorage.setItem('userId', authData.user_id);
      localStorage.setItem('birdCage', authData.cage);
      localStorage.setItem('expiryDate', expirationDate);
      localStorage.setItem('userName', authData.username);
      localStorage.setItem('lastLogIn', authData.lastlogin);
      localStorage.setItem('email_address', authData.email_address);
      localStorage.setItem('userLevel', authData.userlevel);
    },
    checkStoredToken({ commit }) {
      const localToken = localStorage.getItem('token');
      const localUserId = localStorage.getItem('userId');
      const localCage = localStorage.getItem('birdCage');
      const localExpiryDate = localStorage.getItem('expiryDate');
      const localUserName = localStorage.getItem('userName');
      const localLastLogIn = localStorage.getItem('lastLogIn');
      const localEmail = localStorage.getItem('email_address');
      const localUserLevel = localStorage.getItem('userLevel');
      const now = new Date();
      const ts = Math.round((now.getTime() / 1000));
      if (localToken && ts <= localExpiryDate) {
        commit('authUser', {
          token: localToken,
          userId: localUserId,
          birdCage: localCage,
          userName: localUserName,
          lastLogIn: localLastLogIn,
          email_address: localEmail,
          userLevel: localUserLevel,
        });
      }
    },
    logout({ commit }) {
      commit('clearAuthData');
      localStorage.removeItem('token');
      localStorage.removeItem('expiryDate');
      localStorage.removeItem('userId');
      localStorage.removeItem('birdCage');
      localStorage.removeItem('userName');
      localStorage.removeItem('lastLogIn');
      localStorage.removeItem('email_address');
      localStorage.removeItem('userLevel');
    },
  },
  getters: {
    isAuthenticated(state) {
      return state.idToken !== null;
    },
    authData(state) {
      const authorizationData = {};
      authorizationData.userId = state.userId;
      authorizationData.cage = state.birdCage;
      authorizationData.token = state.idToken;
      authorizationData.userEmail = state.userEmail;
      return authorizationData;
    },
    userLevel(state) {
      return state.userLevel;
    },
    currentUserName(state) {
      return state.currentUserName;
    },
    isLoggedIn(state) {
      return state.loggedIn;
    },
  },
});
