<template>
  <div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-8 large-4 large-offset-4">
        <h1>Login</h1>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-8 large-4  large-offset-4">
        <form v-on:submit="login">
          <label>Username<sup class="text-red">*</sup></label>
          <input required type="text" v-model="user.email_address">
          <label>Password<sup class="text-red">*</sup></label>
          <input required type="password" v-model="user.password">
          <button class="button">Submit</button>
        </form>
      </div>
    </div>
    <div class="grid-x grid-padding-x pad-top">
      <div class="cell small-12 medium-8 large-4  large-offset-4">
        <p class="marg-none">
          <router-link
            to="/request-password">
            <strong class="text-purple">Forgot your password?</strong>
          </router-link>
        </p>
      </div>
    </div>
    <div
      v-if="message.length > 0"
      class="message-wrap">
        <span v-on:click="message = ''" class="close-message">X</span>
        <h3 class="message-text">{{ message }}</h3>
    </div>
  </div>
</template>

<script>
import axios from '../axios';

export default {
  name: 'Index',
  data() {
    return {
      user: {
        email_address: '',
        password: '',
      },
      message: '',
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },
  methods: {
    clearMessage() {
      this.message = '';
    },
    login(event) {
      event.preventDefault();
      axios.post('/users/login.json', this.user)
        .then((response) => {
          if (response.data.response === 1) {
            this.message = 'Sorry that email or password was not recognised. Please try again.';
          } else {
            this.$store.dispatch('saveToken', response.data.response);
            this.$router.push('/dashboard');
          }
        });
    },
  },
  mounted() {
    if (this.isLoggedIn === true) {
      this.$router.push('/dashboard');
    }
  },
};
</script>
